import { useState, useEffect } from 'react';
import {
  DESKTOP_BREAKPOINT,
  TABLET_BREAKPOINT
} from '../utils/helpers/constants';

const useScreenSize = () => {
  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  const isLarge = width >= DESKTOP_BREAKPOINT;
  const isMedium = width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT;
  const isSmall = width < TABLET_BREAKPOINT;

  useEffect(() => {
    if (isBrowser) {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return { isLarge, isMedium, isSmall };
};

export default useScreenSize;
